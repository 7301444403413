<template>
  <div v-loading="loading">
    <!--    上部开始-->
    <div class="statistical-top">
      <div class="el-nav">
        <div class="goback vertical-center">
          <div class="goback-left vertical-center" @click="goBack">
            <img class="back" src="~assets/images/data/return.png" alt="" /><img class="back_hover" src="~assets/images/data/back_hover.png" alt="" /><span>返回</span>
          </div>

          <p class="line"></p>

          <div class="tag">{{ type }}</div>

          <div class="goback-text">{{ title }}</div>
          <div class="el-nav-right">
            每天
            <span>1</span>
            点左右更新前一日数据
          </div>
        </div>
        <el-divider></el-divider>
        <div class="statistical-center">
          <div class="statistical-center-list space-between">
            <div class="statistical-center-item">
              <div class="statistical-center-item-title">阅读（播放）总数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.read_count) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.read_count) === 0 ? 'noBg' : Number(yesterday_data.read_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.read_count) === 0 ? '' : Number(yesterday_data.read_count) > 0 ? '+' : '-' }}{{ yesterday_data.read_count }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均阅读(播放)量：{{ week_data.read_count }}</span>
              </div>
            </div>
            <div class="statistical-center-item">
              <div class="statistical-center-item-title">点赞总数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.good_count) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.good_count) === 0 ? 'noBg' : Number(yesterday_data.good_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.good_count) === 0 ? '' : Number(yesterday_data.good_count) > 0 ? '+' : '-' }}{{ yesterday_data.good_count }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均点赞量：{{ week_data.good_count }}</span>
              </div>
            </div>
            <!-- <div class="statistical-center-item">
              <div class="statistical-center-item-title">评论总数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.comment_count) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.comment_count) === 0 ? 'noBg' : Number(yesterday_data.comment_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.comment_count) > 0 ? '+' : '' }}{{ yesterday_data.comment_count }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均评论量：{{ week_data.comment_count }}</span>
              </div>
            </div> -->
            <div class="statistical-center-item">
              <div class="statistical-center-item-title">收藏总数</div>
              <div class="statistical-center-item-num">
                {{ Number(total.collect_count) }}
              </div>

              <div class="statistical-center-item-foot">
                昨日：
                <el-tag :class="Number(yesterday_data.collect_count) === 0 ? 'noBg' : Number(yesterday_data.collect_count) > 0 ? 'blueBg' : 'redBg'"
                  >{{ Number(yesterday_data.collect_count) === 0 ? '' : Number(yesterday_data.collect_count) > 0 ? '+' : '-' }}{{ yesterday_data.collect_count }}</el-tag
                >
              </div>
              <el-divider></el-divider>
              <div class="statistical-center-item-jun">
                <span>本周日均收藏量：{{ week_data.collect_count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistical-bottom">
      <div class="statistical-bottom-table">
        <div class="statistical-bottom-table-title space-between">
          <div class="statistical-bottom-table-title-left">分日报表</div>
          <div class="statistical-bottom-table-title-right vertical-center" @click="downtDetail('date')">
            <img src="~assets/images/index/down.png" alt="" class="down" />
            <img src="~assets/images/index/down-hover.png" alt="" class="down-hover" />
            导出Excel
          </div>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#F6F8F9', fontSize: '14px', fontWeight: '400', color: '#1C1F21' }"
            :cell-style="{ fontSize: '14px', fontWeight: '400', color: '#71777D' }"
            stripe
            style="width: 100%"
            id="detail"
          >
            <el-table-column prop="date" label="日期" width="150"> </el-table-column>
            <el-table-column v-if="type === '文章'" prop="read_count" label="阅读数" sortable align="center"> </el-table-column>
            <el-table-column v-if="type !== '文章'" prop="read_count" label="播放数" sortable align="center"> </el-table-column>
            <el-table-column prop="good_count" label="点赞数" sortable align="center"> </el-table-column>
            <el-table-column prop="collect_count" label="收藏数" sortable align="center"> </el-table-column>
            <!-- <el-table-column prop="comment_count" label="评论数" sortable align="center"> </el-table-column> -->
            <el-table-column v-if="type === '文章'" prop="download_count" label="资料下载数" sortable align="center"> </el-table-column>
          </el-table>
        </div>

        <el-pagination
          v-if="tableData.length > 0 && totalNum > 10"
          class="page"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout=" prev, pager, next,total, jumper"
          :total="totalNum"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../router';
export default {
  data() {
    return {
      loading: false,
      activeName: 'first',
      value: 0,
      radio: 0,
      readingsNum: 698457,
      likesNum: 15,
      commentsNum: 0,
      cocollectionsNum: 0,
      readingsOldNum: 0,
      likesOldNum: 15,
      commentsOldNum: -2,
      cocollectionsOldNum: 0,
      noDow: false,
      type: '',
      title: '',
      model_id: '',
      page: 1,
      pageSize: 10,
      totalNum: 0,
      tableData: [],
      yesterday_data: {},
      week_data: {},
      total: {},
    };
  },
  created() {
    console.log(this.$route.query.type, 'lllllllllllllll');
    switch (this.$route.query.type) {
      case 'article':
        this.type = '文章';
        break;
      case 'video':
        this.type = '视频';
        break;
      case 'replay':
        this.type = '直播';
        break;
      case 'posts':
        this.type = '帖子';
        break;
    }
    console.log(this.type, '======');
    this.title = this.$route.query.title;
    this.model_id = this.$route.query.id;
    if (this.model_id) {
      this.judgeType();
    }
  },
  mounted() {},
  methods: {
    judgeType() {
      if (this.type === '文章') {
        this.getDayStatisticsList(this.model_id, 1);
        this.getOverView(1, this.model_id);
      } else if (this.type === '视频') {
        this.getDayStatisticsList(this.model_id, 2);
        this.getOverView(2, this.model_id);
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.judgeType();
    },
    // 下载
    downtDetail(type) {
      let content_type = '';
      if (this.type === '文章') {
        content_type = 1;
      } else if (this.type === '视频') {
        content_type = 2;
      } else {
        content_type = 4;
      }
      let url = `${this.$api.exportList}?content_type=${content_type}&export_type=${type}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);

        window.open(res.data.data);
      });
    },
    // 获取数据总览
    getOverView(content_type, id) {
      this.loading = true;
      let url = `${this.$api.Overview}?content_type=${content_type}&model_id=${id}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.loading = false;
          this.yesterday_data = res.data.data.yesterday_data;
          this.week_data = res.data.data.week_data;
          this.total = res.data.data.total;
        }
      });
    },
    // 获取分日列表
    getDayStatisticsList(id, content_type) {
      let url = `${this.$api.StatisticsDetail}?content_type=${content_type}&page=${this.page}&pageSize=${this.pageSize}&model_id=${id}`;
      this.$http.get(url, true).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.tableData = res.data.data.data;
          this.totalNum = res.data.data.total;
        }
      });
    },
    goBack() {
      let query = {
        type: this.$route.query.type,
      };
      if (this.$route.query.all !== undefined) {
        query = {
          type: this.$route.query.all,
        };
      }
      this.$router.push({
        path: '/analysis/content/indexAll',
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/data/content/index.less';
</style>
